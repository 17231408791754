import React, { useState, useEffect } from "react"
import Layout from "../components/Layout/layout"
import client from '../components/utils/client'

// comps
import Banner from "./common/Banner";
import FirstGrid from "./tecnologia/comps/FirstGrid";
import SecondGrid  from "./tecnologia/comps/SecondGrid";

const Tecnologia = () =>  {
  const [data, setData] = useState({});

  useEffect(() => {
    const handleGetData = () => {
      client()
        .get("content-group/11")
        .then(res => {
          let data = res.data || [];
          data.Contents.map(item => {
            if(item.Type === "image") {
                let obj = Object.assign(item);
                obj.Value = "https://zl-ezata-api.herokuapp.com" + item.Value;
                return obj;
            }
        })  
          setData(data);
        })
        .catch(err => {
          console.log(err);
        })
    }

    handleGetData();
  }, [])

  return (
    <Layout infoPage={{page: "tecnologia"}}>
      <div className="engenharia">
        <Banner text="Tecnologia" />
        <FirstGrid data={data} />
        <SecondGrid data={data} />
      </div>
    </Layout>
  )  
}
export default Tecnologia;